import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/iron_deficiency/ida.png"
import ida1 from "../../../assets/images/portfolio_items/iron_deficiency/ida1.png"
import ida2 from "../../../assets/images/portfolio_items/iron_deficiency/ida2.png"
import ida3 from "../../../assets/images/portfolio_items/iron_deficiency/ida3.png"
import ida4 from "../../../assets/images/portfolio_items/iron_deficiency/ida4.png"
import ida5 from "../../../assets/images/portfolio_items/iron_deficiency/ida5.png"
import ida6 from "../../../assets/images/portfolio_items/iron_deficiency/ida6.png"

export default () => 
<div>
    <SEO title={'Iron ICON Worldwide | Iron Deficiency - Mobile and Brand Development'}
    description="Mobile app and brand development for an innovative patient-focussed destination that provides content about Iron Deficiency. Discover it on ICON's portfolio!"
    canonical={'https://icon-worldwide.com/works/iron-deficiency'}/>
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt=" Iron Deficieny, ICON Worldwide portfolio,  Mobile App and Brand Development" title="ICON Worldwide portfolio, Iron Deficiency,  Mobile App and Brand Development"/>
            <div className="title"><h1>Iron Deficiency<span></span></h1>
            <h2>Mobile App and Brand Development</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p><a  href="https://irondeficiency.com/">Irondeficiency.com</a> is a patient focussed destination providing deep content related to Iron Deficiency. ICON developed the brand, the platform, mobile apps and manages the social media.</p>
        <div className="portfolio-images">
            <img src={ida1} alt=" Iron Deficieny, ICON Worldwide portfolio,  Mobile App and Brand Development" title="ICON Worldwide portfolio, Iron Deficiency,  Mobile App and Brand Development"/>
            <img src={ida3} alt=" Iron Deficieny, ICON Worldwide portfolio,  Mobile App and Brand Development" title="ICON Worldwide portfolio, Iron Deficiency,  Mobile App and Brand Development"/>
            <img src={ida5} alt=" Iron Deficieny, ICON Worldwide portfolio,  Mobile App and Brand Development" title="ICON Worldwide portfolio, Iron Deficiency,  Mobile App and Brand Development"/>
            <img src={ida2} alt=" Iron Deficieny, ICON Worldwide portfolio,  Mobile App and Brand Development" title="ICON Worldwide portfolio, Iron Deficiency,  Mobile App and Brand Development"/>
            <img src={ida4} alt=" Iron Deficieny, ICON Worldwide portfolio,  Mobile App and Brand Development" title="ICON Worldwide portfolio, Iron Deficiency,  Mobile App and Brand Development"/>
            <img src={ida6} alt=" Iron Deficieny, ICON Worldwide portfolio,  Mobile App and Brand Development" title="ICON Worldwide portfolio, Iron Deficiency,  Mobile App and Brand Development"/>
        </div>
        <WorkFooter previous="ceibs" next="elkuch"/>
        </div>
    </div>
</div>